import { gTagPush, isActive, debug } from '~/plugins/google-tag/helpers'
import {
  type GoogleECommerceAddPaymentInfoParams,
  GoogleECommerceAffiliation,
  type GoogleECommerceBeginCheckoutParams,
  GoogleECommerceEventType,
  type GoogleECommerceItem,
  type GoogleECommercePurchaseParams,
  type GoogleECommerceViewItemListParams,
} from '~/models/ecommerce-google'
import type {
  Currency,
  TicketDetails,
  TicketsOrderItemDetails,
} from '~/models/tickets'
import type { EventLite } from '~/models/events'
import { EventTypeNamesMap } from '~/models/events'
import { useECommerceHelper } from '~/composables/use-ecommerce-helper'
import { ECommerceBrands } from '~/models/ecommerce'

const {
  prepareECommerceItemId,
  prepareECommercePrice,
  getECommercePriceFromTicket,
} = useECommerceHelper()

export function getGoogleECommerceItemFromTicket(
  ticket: TicketDetails | TicketsOrderItemDetails,
  currency: Currency,
  event?: EventLite | null,
  quantity: number = 1,
  coupon = '',
  discount = 0,
  index?: number
): GoogleECommerceItem {
  const brand = ECommerceBrands.HubTicket
  const affiliation = GoogleECommerceAffiliation.Hub

  const category = event
    ? prepareGoogleECommerceCategory([
        EventTypeNamesMap[event.type],
        event.name,
      ])
    : {}

  return {
    item_id: prepareECommerceItemId(ticket.id || 0, brand),
    item_name: ticket.name,
    ...category,
    affiliation,
    price: getECommercePriceFromTicket(ticket, currency),
    discount: prepareECommercePrice(discount),
    coupon,
    quantity,
    item_brand: brand,
    index,
  }
}

export function sendAddToCartGoogleECommerceEvent(
  currency: Currency,
  items: GoogleECommerceItem[]
) {
  sendGoogleECommerceEvent(GoogleECommerceEventType.AddToCart, {
    currency,
    items,
    value: calcTotalPriceByItems(items),
  })
}

export function sendRemoveFromCartGoogleECommerceEvent(
  currency: Currency,
  items: GoogleECommerceItem[]
) {
  sendGoogleECommerceEvent(GoogleECommerceEventType.RemoveFromCart, {
    currency,
    items,
    value: calcTotalPriceByItems(items),
  })
}

export function sendViewItemListGoogleECommerceEvent(
  params: GoogleECommerceViewItemListParams,
  items: GoogleECommerceItem[]
) {
  sendGoogleECommerceEvent(GoogleECommerceEventType.ViewItemList, {
    ...params,
    items,
  })
}

export function sendViewItemGoogleECommerceEvent(
  currency: Currency,
  items: GoogleECommerceItem[]
) {
  sendGoogleECommerceEvent(GoogleECommerceEventType.ViewItem, {
    currency,
    items,
    value: calcTotalPriceByItems(items),
  })
}

export function sendViewCartGoogleECommerceEvent(
  currency: Currency,
  items: GoogleECommerceItem[]
) {
  sendGoogleECommerceEvent(GoogleECommerceEventType.ViewCart, {
    currency,
    items,
    value: calcTotalPriceByItems(items),
  })
}

export function sendBeginCheckoutGoogleECommerceEvent(
  params: GoogleECommerceBeginCheckoutParams,
  items: GoogleECommerceItem[]
) {
  sendGoogleECommerceEvent(GoogleECommerceEventType.BeginCheckout, {
    ...params,
    items,
    value: calcTotalPriceByItems(items),
  })
}

export function sendAddPaymentInfoGoogleECommerceEvent(
  params: GoogleECommerceAddPaymentInfoParams,
  items: GoogleECommerceItem[]
) {
  sendGoogleECommerceEvent(GoogleECommerceEventType.AddPaymentInfo, {
    ...params,
    items,
    value: params.value,
  })
}

export function sendPurchaseGoogleECommerceEvent(
  params: GoogleECommercePurchaseParams,
  items: GoogleECommerceItem[]
) {
  sendGoogleECommerceEvent(GoogleECommerceEventType.Purchase, {
    ...params,
    items,
  })
}

function sendGoogleECommerceEvent(event: GoogleECommerceEventType, data: any) {
  if (!isActive()) {
    debug('Send Google ECommerce event', event)
    return
  }
  gTagPush({ ecommerce: null }) // Clear the previous ecommerce object.
  gTagPush({ event, ecommerce: data })
}

function calcTotalPriceByItems(items: GoogleECommerceItem[]): number {
  return items?.reduce(
    (acc, item) => acc + (item.price || 0) * (item.quantity || 1),
    0
  )
}

function prepareGoogleECommerceCategory(
  categories: string[] & { length: 1 | 2 | 3 | 4 | 5 }
): string {
  return (
    categories
      .filter((category) => category)
      // item_category, item_category2, item_category3, item_category4, item_category5
      .reduce((acc, category, index) => {
        acc['item_category' + (index ? index + 1 : '')] = category
        return acc
      }, {} as any)
  )
}
