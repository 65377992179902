<template>
  <div
    v-if="
      (isNativeApp && authStore.auth && event?.is_has_ticket) ||
      pageEditorStore.isEditMode
    "
    :id="uuid"
    :style="{
      'scroll-margin-top': pageEditorStore.scrollMarginTop,
    }"
    class="px-6 xl:px-0"
  >
    <div
      class="bg-amber-50 border border-1 border-amber-300 rounded-xl px-6 py-3 flex gap-2 justify-between items-center hover:opacity-80 hover:cursor-pointer"
      @click="open"
    >
      <div class="flex flex-col w-[80%] items-start">
        <div>
          <span>{{ props.formData.title }}{{ ' ' }}</span>
          <span
            class="font-bold"
            :class="!formData.eventId && 'text-amber-700'"
          >
            {{ event?.name || 'Please, select event' }}{{ ' ' }}
          </span>
          <span v-if="ticket" class="font-bold">({{ ticket?.name }})</span>
        </div>
        <div
          class="flex gap-2 items-stretch sm:items-baseline mt-2 flex-col sm:flex-row"
        >
          <BaseButton
            v-if="ticket?.upgradable"
            size="md"
            look="border"
            class="max-w-full"
            @click.prevent="onUpgrade"
          >
            <div class="truncate">
              {{ props.formData.upgradeButtonText || 'Upgrade ticket' }}
            </div>
            <BaseIcon
              name="outline_chevron_double_up"
              class="ml-2 shrink-0"
              size="xs"
            />
          </BaseButton>
          <BaseLink
            v-if="eventsStore.event"
            :href="getHubLink(eventsStore.event.slug)"
            :class="!isHubOpen && 'pointer-events-none'"
            :target="isApp ? '_self' : '_blank'"
          >
            <BaseButton
              look="solid"
              :theme="isHubOpen ? 'primary' : 'disabled'"
              rounded="xl"
              size="md"
              class="w-full"
              :disabled="!isHubOpen"
            >
              <div>
                {{ props.formData.meetingSystemButtonText || 'Meeting system' }}
              </div>
            </BaseButton>
          </BaseLink>
        </div>
      </div>
      <BaseIcon
        name="qr_code"
        size="none"
        class="w-[16%] shrink-0 max-w-16 ml-auto"
      />
    </div>
    <BaseModal
      :is-open="isOpen"
      :fullscreen="true"
      class="!z-50"
      @close="close"
    >
      <template #header>
        <div class="px-1 py-1 flex justify-end">
          <BaseButton theme="white" size="sm" class="w-8 !p-0" @click="close">
            <BaseIcon name="outline_x" />
          </BaseButton>
        </div>
      </template>
      <template #body>
        <div
          class="p-3 pb-8 sm:p-10 sm:pb-10 pt-0 sm:pt-0 flex flex-col items-center justify-center text-center"
        >
          <UsersUserAvatar
            :src="getAvatar(authStore.profile)"
            :abbr="getAbbr(authStore.profile)"
            size="3xl"
            class="!w-auto"
          >
          </UsersUserAvatar>
          <div class="mt-1 font-semibold text-md">
            {{ authStore.profile?.first_name }}
            {{ authStore.profile?.last_name }}
          </div>
          <div class="w-[60%] mt-8" v-html="qrCodeHTML" />
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<script setup lang="ts">
import { usePageEditor } from '~/stores/page-editor'
import type { Event } from '~/models/events'
import { useTickets } from '~/stores/tickets'
import { YandexMetrikaGoals } from '~/plugins/yandex-metrika/index.client'
const qrToString = (await import('qrcode')).toString

const pageEditorStore = usePageEditor()
const authStore = useAuth()
const isLoading = ref(false)
const { isApp } = useDetect()
const {
  $yandexMetrika: { reachGoal },
} = useNuxtApp()
const isOpen = ref(false)
const ticketsStore = useTickets()
const isNativeApp = process.client && isApp
const qrCodeHTML = ref('')

interface PropsInterface {
  uuid: string
  formData: any
}

const props = defineProps<PropsInterface>()

const eventsStore = useEvents()
const { getEvent, getHubLink } = eventsStore

const event = ref<Event | null>(null)

async function load() {
  if (!authStore.auth || !props.formData.eventId || process.server) {
    return
  }
  isLoading.value = true
  event.value = await getEvent(props.formData.eventId, true)
  if (event.value.is_has_ticket) {
    await ticketsStore.fetchUserTicketList(true)
  }
  generateQRCode()
  isLoading.value = false
}

watch(
  [() => props.formData, () => authStore.auth],
  () => {
    load()
  },
  { deep: true }
)

const ticket = computed(() => {
  return ticketsStore.userTickets.find(
    (ticket) => ticket.event_id === props.formData?.eventId
  )
})

const isHubOpen = computed(() =>
  eventsStore.event ? eventsStore.isHubOpen(eventsStore.event) : false
)

onMounted(async () => {
  await load()
})

function open() {
  isOpen.value = true
}

function close() {
  isOpen.value = false
}

function onUpgrade() {
  navigateTo(useLocaleRoute()(`/users/me/tickets/${ticket.value?.id}`))

  reachGoal(YandexMetrikaGoals.HomeUpgradeClick, {
    home_upgrade_click: {
      home_upgrade_click_ticket_id: ticket.value?.id,
      home_upgrade_click_ticket_name: ticket.value?.name,
      home_upgrade_click_event_id: ticket.value?.event_id,
    },
  })
}

function getQRUrl() {
  return `wnhub.io/app-link?link=https://wnhub.io/events/${props.formData.eventId}/members/${authStore.profile?.id}?e`
}

function generateQRCode() {
  qrToString(
    getQRUrl(),
    { errorCorrectionLevel: 'H', margin: 0 },
    (error: any, data: any) => {
      if (error) throw error
      qrCodeHTML.value = data
    }
  )
}
</script>
