import { defineStore } from 'pinia'
import { FetchError } from 'ofetch'
import {
  getVideoSrc,
  VideoSource,
  getVideoPreview,
  getVideoPreviewSmall,
  getVideoIdFromUrl,
} from '~core/plugins/helpers/videoHelper'
import {
  isSortTheSame,
  isCategoryTheSame,
  isQueryTheSame,
  isEventsIdsTheSame,
  FilterParams,
  setSort,
} from './params-helper'
import { useAuth } from './auth'
import type { FilterItem } from '~/models/filter'
import type { CheckboxFilterItem } from '~/models/common'
import { NavigationSortType } from '~/models/navigation'
import type { Video } from '~/models/video'

export const VideoEndpointMap = {
  Base: '/lecture-archive',
  Auth: '/lecture-archive/me',
} as const

type FilterResponse = { categories: FilterItem[]; events: FilterItem[] }

interface VideoState {
  video: Video | null
  isVideoLoaded: boolean
  isFiltersLoading: boolean
  isCategoryChanging: boolean
  // eventsFilterItems are mutated by checkboxes in VideoEventsFilter
  eventsFilterItems: CheckboxFilterItem[]
  relatedVideoList: Video[]
  isRelatedVideoListLoaded: boolean
  filters: null | FilterResponse
  isListLoaded: boolean
}

export const useVideo = defineStore('video', () => {
  const host = useHost()
  const authStore = useAuth()
  const i18n = useI18n()
  const router = useRouter()

  // TODO: https://app.asana.com/0/1202924432057564/1203829013202629
  const baseURL = useRuntimeConfig().public.gatewayApi
  const elasticURL = useRuntimeConfig().public.gatewayElastic

  const state = reactive<VideoState>({
    video: null,
    isCategoryChanging: false,
    isVideoLoaded: false,
    isFiltersLoading: false,
    eventsFilterItems: [],
    relatedVideoList: [],
    isRelatedVideoListLoaded: false,
    filters: null,
    isListLoaded: false,
  })

  const videoEndpoint = computed(() =>
    authStore.auth ? VideoEndpointMap.Auth : VideoEndpointMap.Base
  )

  const initVideoList = () => {
    const path = VideoEndpointMap.Base
    // TODO: uncomment later
    // We have temporarily disabled the language filter, until there are no translations. This allows user to see more videos

    // const list = useInfinityList<Video>(path, baseURL, (params) => {
    //   params[FilterParams.Language] = i18n.locale.value
    // })
    const list = useInfinityList<Video>(path, elasticURL)

    list.state.params = {
      per_page: 6,
    }

    return list
  }

  const videoList = initVideoList()

  async function fetchVideo(id: number) {
    if (state.video?.id === Number(id)) {
      return
    }

    if (isNaN(Number(id))) {
      throw useCreateScreenError({ message: 'video id should be a number' })
    }

    state.video = null

    state.isVideoLoaded = false

    try {
      const { data } = await useAuthFetch<{ data: Video }>(
        `${videoEndpoint.value}/${id}`,
        {
          baseURL: elasticURL,
        }
      )

      state.video = data

      await setTempVideoUrl()
    } catch (error: any) {
      const errorStatus = (error as FetchError).status
      if (errorStatus === 404) {
        throw useCreateScreenError({ message: 'no such video' })
      } else if (errorStatus === 403) {
        throw useCreateScreenError({
          message: 'video is not available to the user',
          statusCode: 403,
        })
      } else {
        throw error
      }
    } finally {
      state.isVideoLoaded = true
    }
  }

  async function fetchFilters(categories?: number[]) {
    try {
      state.isFiltersLoading = true
      const params = {
        [FilterParams.Categories]:
          categories || videoList.state.params[FilterParams.Categories],
      }
      const { data } = await useAuthFetch<{ data: FilterResponse }>(
        `${videoEndpoint.value}/filter`,
        {
          baseURL: elasticURL,
          params,
        }
      )
      data.categories = data.categories.map((category) => {
        category.name = parseFieldLocales(category.name as string) || ''
        return category
      })
      state.filters = data

      const events = (videoList.state.params[FilterParams.EventArray] ||
        []) as number[]

      setEventsFilterItems(
        data.events.map((event) => ({
          id: event.id,
          label: getFieldLocale(
            parseFieldLocales(event.name as string) || '',
            i18n.locale.value
          ),
          value: events.includes(event.id),
          count: event.count,
        }))
      )
    } finally {
      state.isFiltersLoading = false
    }
  }

  const resetVideo = () => {
    state.video = null
  }

  function getVideoPageLink(id: number, categorySlug: string) {
    return `${host}/video/${categorySlug}/item-${id}`
  }

  function setEventsFilterItems(items: CheckboxFilterItem[]) {
    state.eventsFilterItems = items
  }

  const eventsFilterActiveIds = computed(() =>
    state.eventsFilterItems.reduce((acc, event) => {
      event.value && acc.push(event.id)
      return acc
    }, [] as number[])
  )

  function onEventsFilterUpdate() {
    if (eventsFilterActiveIds.value.length) {
      videoList.state.params[FilterParams.EventArray] =
        eventsFilterActiveIds.value
    } else {
      delete videoList.state.params[FilterParams.EventArray]
    }

    router.push({
      query: {
        events: eventsFilterActiveIds.value.join(',') || undefined,
      },
    })

    videoList.reset()
    updateVideoList(false)
  }

  function resetVideoListWithFilters(
    sort: NavigationSortType,
    query?: string,
    categoryId?: number,
    eventsIds: number[] = []
  ) {
    const isEndpointTheSame = videoEndpoint.value === videoList.state.endpoint

    if (
      isEndpointTheSame &&
      isSortTheSame(videoList.state.params, sort) &&
      isCategoryTheSame(videoList.state.params, categoryId) &&
      isQueryTheSame(videoList.state.params, query) &&
      isEventsIdsTheSame(videoList.state.params, eventsIds)
    ) {
      return
    }

    videoList.state.endpoint = videoEndpoint.value

    setSort(videoList.state.params, sort)

    if (categoryId) {
      videoList.state.params[FilterParams.Categories] = [categoryId]
    } else {
      delete videoList.state.params[FilterParams.Categories]
    }

    if (query) {
      videoList.state.params[FilterParams.Query] = query
    } else {
      delete videoList.state.params[FilterParams.Query]
    }

    if (eventsIds.length) {
      videoList.state.params[FilterParams.EventArray] = eventsIds
    } else {
      delete videoList.state.params[FilterParams.EventArray]
    }

    videoList.reset()
    state.isListLoaded = false
  }

  function setEventsFilterItemsValue(eventIds: number[]) {
    eventIds.forEach((id) => {
      const filterItem = state.eventsFilterItems.find((item) => id === item.id)
      filterItem && (filterItem.value = true)
    })
  }

  const isListHasFilters = computed(() => {
    return (
      videoList.state.params[FilterParams.Query] ||
      videoList.state.params[FilterParams.EventArray]
    )
  })

  function clearEventsFilter() {
    state.eventsFilterItems.forEach((item) => {
      item.value = false
    })
  }

  const fetchRelatedVideoList = async (
    categoryId: number,
    exceptVideoId: number
  ) => {
    state.relatedVideoList = []
    state.isRelatedVideoListLoaded = false

    try {
      const { data } = await useAuthFetch<{ data: Video[] }>(
        videoEndpoint.value,
        {
          baseURL: elasticURL,
          params: {
            // [FilterParams.Language]: i18n.locale.value,
            [FilterParams.Categories]: [categoryId],
            per_page: 6,
          },
        }
      )

      state.relatedVideoList = data.filter((item) => item.id !== exceptVideoId)
    } catch (error: any) {
      useLogError(error)
    } finally {
      state.isRelatedVideoListLoaded = true
    }
  }

  const VideoPreviewFallbackGetters: Record<
    string,
    (event: Event, id: string) => void
  > = {
    [VideoSource.Youtube]: (event, id) => {
      const imgElement = event.currentTarget as HTMLImageElement

      // if we got youtube placeholder, try low res thumbnail
      if (imgElement.naturalWidth > 0 && imgElement.naturalWidth <= 120) {
        imgElement.src = getVideoPreviewSmall(VideoSource.Youtube, id)
      }
    },
  }

  function getVideoId(video: Video): string {
    return (
      video.video_id || getVideoIdFromUrl(video.video_source, video.video_url)
    )
  }

  // TODO: uncomment later
  // We have temporarily disabled the language filter, until there are no translations. This allows user to see more videos

  // watch(
  //   () => i18n.locale.value,
  //   () => {
  //     videoList.getPage(1)
  //   }
  // )

  async function setTempVideoUrl() {
    if (
      !state.video ||
      state.video.video_source !== VideoSource.WNMeet ||
      state.video.temp_video_url
    ) {
      return
    }

    try {
      const { data } = await useAuthFetch<{ data: { url: string } }>(
        `/${state.video.event.id}/video-url`,
        {
          baseURL,
          params: {
            url: state.video.video_url,
          },
        }
      )

      state.video.temp_video_url = data.url
    } catch (error: any) {
      useLogError(error)
    }
  }

  watch(
    () => eventsFilterActiveIds.value,
    (newValue, oldValue) => {
      // avoid excess fetches
      const paramEventIds =
        (videoList.state.params[FilterParams.EventArray] as number[]) || []
      if (
        (oldValue.length === newValue.length &&
          oldValue.every((id) => newValue.includes(id))) ||
        (newValue.length === paramEventIds.length &&
          newValue.every((id) => paramEventIds.includes(id)))
      ) {
        return
      }

      if (!state.isListLoaded || useNuxtApp().isHydrating) {
        return
      }
      onEventsFilterUpdate()
    }
  )

  const updateVideoList = async (updateFilters = true) => {
    if (updateFilters) {
      process.client && (state.isCategoryChanging = true)
      await Promise.all([videoList.getPage(), fetchFilters()])
      state.isCategoryChanging = false
    } else {
      await videoList.getPage()
    }
    state.isListLoaded = true
  }

  function getEventIdsFromQuery() {
    return (
      router.currentRoute.value.query.events
        ?.toString()
        .split(',')
        .map((item) => Number(item)) || []
    )
  }

  function isOpenInBrowser(source: VideoSource) {
    const { isApp } = useDetect()
    const { isIOS } = useDevice()

    return isApp && isIOS && source === VideoSource.Google
  }

  return {
    video: toRef(state, 'video'),
    isVideoLoaded: toRef(state, 'isVideoLoaded'),
    isFiltersLoading: toRef(state, 'isFiltersLoading'),
    isListLoaded: toRef(state, 'isListLoaded'),
    eventsFilterItems: toRef(state, 'eventsFilterItems'),
    relatedVideoList: toRef(state, 'relatedVideoList'),
    isRelatedVideoListLoaded: toRef(state, 'isRelatedVideoListLoaded'),
    isCategoryChanging: toRef(state, 'isCategoryChanging'),
    list: videoList.state,
    filters: toRef(state, 'filters'),
    fetchFilters,
    fetchVideo,
    resetVideo,
    allRecordsLoaded: videoList.allRecordsLoaded,
    fetch: videoList.fetch,
    getVideoList: updateVideoList,
    getPage: updateVideoList,
    nextPage: videoList.nextPage,
    resetVideoListWithFilters,
    getVideoPageLink,
    isListHasFilters,
    setEventsFilterItems,
    clearEventsFilter,
    eventsFilterActiveIds,
    fetchRelatedVideoList,
    VideoPreviewFallbackGetters,
    getVideoSrc,
    getVideoPreview,
    getVideoPreviewSmall,
    getVideoId,
    setEventsFilterItemsValue,
    getEventIdsFromQuery,
    isOpenInBrowser,
  }
})
