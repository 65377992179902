// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?hl=en&client_type=gtm#add_payment_info_item

import type { Currency } from '~/models/tickets'

export enum GoogleECommerceEventType {
  AddPaymentInfo = 'add_payment_info',
  AddShippingInfo = 'add_shipping_info',
  AddToCart = 'add_to_cart',
  AddToWishlist = 'add_to_wishlist',
  BeginCheckout = 'begin_checkout',
  Purchase = 'purchase',
  Refund = 'refund',
  RemoveFromCart = 'remove_from_cart',
  SelectItem = 'select_item',
  SelectPromotion = 'select_promotion',
  ViewCart = 'view_cart',
  ViewItem = 'view_item',
  ViewItemList = 'view_item_list',
  ViewPromotion = 'view_promotion',
}

export enum GoogleECommerceAffiliation {
  Hub = 'hub',
}

export interface GoogleECommerceItem {
  item_id?: string // One of item_id or item_name is required
  item_name?: string // One of item_id or item_name is required
  affiliation?: GoogleECommerceAffiliation
  coupon?: string
  discount?: number
  index?: number
  item_brand?: string
  item_category?: string
  item_category2?: string
  item_category3?: string
  item_category4?: string
  item_category5?: string
  item_list_id?: string
  item_list_name?: string
  item_variant?: string
  location_id?: string
  price?: number
  quantity?: number
}

export interface GoogleECommerceViewItemListParams {
  currency: Currency
  item_list_id?: number
  item_list_name?: string
}

export interface GoogleECommerceBeginCheckoutParams {
  currency: Currency
  coupon?: string
}

export interface GoogleECommerceAddPaymentInfoParams {
  currency: Currency
  coupon?: string
  payment_type?: string
  value: number
}

export interface GoogleECommercePurchaseParams {
  currency: Currency
  value: number
  transaction_id: string
  coupon?: string
  shipping?: number
  tax?: number
}
