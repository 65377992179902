import { basePropertiesSchema } from '../base-schema-properties'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'QR Code',
  description:
    'The user will see this block only in the mobile app and only if they have a ticket for the selected event.',
  type: 'object',
  required: ['eventId', 'title'],
  properties: {
    ...basePropertiesSchema,
    eventId: {
      title: 'Event id',
      type: 'number',
    },
    title: {
      title: 'Title',
      type: 'string',
      default: 'Current ticket',
    },
    upgradeButtonText: {
      title: 'Upgrade button text',
      type: 'string',
      default: 'Upgrade ticket',
    },
    meetingSystemButtonText: {
      title: 'Meeting system button text',
      type: 'string',
      default: 'Meeting system',
    },
  },
}
