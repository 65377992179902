<template>
  <div
    :id="uuid"
    class="p-6 md:p-8 bg-white rounded-xl"
    :class="[formData.blockShadow && 'shadow-lg']"
    :style="{
      'scroll-margin-top': pageEditorStore.scrollMarginTop,
      color: formData.textColor,
      'background-color': formData.bgColor,
      '--text-color': formData.textColor,
    }"
  >
    <h2
      v-if="formData.title && !(formData.hideOnEmbed && isInIframe)"
      class="text-2xl md:text-3xl font-bold mb-1 md:mb-2"
      :style="{ textAlign: formData.titleAlign }"
    >
      {{ formData.title }}
    </h2>
    <div
      v-if="formData.description"
      class="marked mb-4"
      v-html="
        getMarkedTextWithBlankLinks(formData.description, formData.linkColor)
      "
    />
    <div class="flex justify-center">
      <div
        class="w-full"
        :style="`max-width: ${
          formData.maxWidth ? formData.maxWidth + 'px' : 'auto'
        }`"
      >
        <BaseRatio
          :ratio="9 / 16"
          class="bg-black rounded-lg overflow-hidden w-full"
        >
          <iframe
            class="w-full h-full rounded-lg"
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            credentialless
            anonymous
            :src="isFirstRender ? undefined : videoSrc"
            :data-src="isFirstRender ? videoSrc : undefined"
            :loading="!isFirstRender ? 'lazy' : undefined"
          />
          <VideoGDriveWNLogo
            v-if="props.formData.source === VideoSource.Google"
          />
          <VideoGDrivePlaceholder
            v-if="isOpenInBrowser(props.formData.soruce)"
            :src="videoSrc"
          />
        </BaseRatio>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { VideoSource, getVideoSrc } from '~core/plugins/helpers/videoHelper'
import { usePageEditor } from '~/stores/page-editor'
import VideoGDrivePlaceholder from '~/components/video/VideoGDrivePlaceholder.vue'

const pageEditorStore = usePageEditor()
const { isOpenInBrowser } = useVideo()

interface PropsInterface {
  formData: any
  uuid: string
  isInIframe?: boolean
}

const props = defineProps<PropsInterface>()

const host = useHost()
const { getMarkedTextWithBlankLinks } = useMarkedText()
const nuxtApp = useNuxtApp()
// custom lazy load optimization only for the first render to improve GPSI LCP
const isFirstRender = computed(() => process.server || nuxtApp.isHydrating)

const videoSrc = computed(() => {
  const { source: videoSource, videoUrl, videoId } = props.formData

  return getVideoSrc({
    videoSource,
    videoUrl,
    videoId,
    host,
  })
})
</script>
