<template>
  <img
    :src="isLazy && isFirstRender && !isInIframe ? undefined : resizeSrc"
    :data-src="isLazy && isFirstRender && !isInIframe ? resizeSrc : undefined"
    :loading="
      isLazy && !isFirstRender && !isInIframe && (height || reservedHeight)
        ? 'lazy'
        : undefined
    "
    :width="width"
    :height="height"
    v-bind="{ ...$attrs }"
  />
</template>

<script setup lang="ts">
const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  width: {
    type: [Number, String],
    default: undefined,
  },
  height: {
    type: [Number, String],
    default: undefined,
  },
  loading: {
    type: String as PropType<'lazy' | 'eager'>,
    default: undefined,
  },
  reservedHeight: {
    type: Boolean,
    default: false,
  },
})

/*
Scrolling Issue:
  Lazy-loaded images with unknown height can result in incorrect scrolling positions.

Current Solution:
  Server-Side Rendering (SSR):
    Images are loading when an user starts to interact with the page (inline/lazy-load.ts). Once all images are resolved, scrolling is performed (useForceLoad).

  Client-Side Rendering (CSR):
    Images are loaded upfront, while lazy-loading is preserved only for images with known dimensions (height/ratio).
    This approach improves UX at the cost of increased network usage.

Other Potential Solutions:
  Scroll Quickly, Adjust Later:
    Perform an initial fast scroll and then re-scroll after all images are resolved in both SSR and CSR.
    Pros: Minimal network load.
    Cons: May negatively impact UX due to jumpy scrolling behavior.

  Optimized Lazy Loading on Scroll Event:
    Start loading images as the user initiate scroll then scoll to the target, focus on optimizing image sizes and prioritizing only those required before reaching the scroll target.
    Pros: Balanced trade-off between UX and network usage.
    Cons: Delay before scrolling.
*/

const isInIframe = computed(() => {
  return useRoute().path.includes('/iframe/')
})
const nuxtApp = useNuxtApp()
const isLazy = computed(() => props.loading === 'lazy')
// custom lazy load optimization only for the first render to improve GPSI LCP
const isFirstRender = computed(() => process.server || nuxtApp.isHydrating)

const resizeSrc = computed(() => {
  return useProcessImage({
    url: props.src,
    width: parseInt(String(props.width)),
    height: parseInt(String(props.height)),
  })
})
</script>
