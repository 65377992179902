export interface ImageSettings {
  url: string
  width?: number
  height?: number
}

// whitelist of domains that are allowed to be processed on resize service
const ALLOWED_DOMAINS = [
  'app2top.com',
  'app2top.ru',
  'cdn.wnhub.io',
  'cdn2.wnhub.io',
  'gameworldobserver.com',
  'talentsingames.com',
]

// TODO: remove once fixes on the backend
const REPLACE_MAP = {
  's3.eu-central-1.amazonaws.com/cdn.wnhub.io': 'cdn.wnhub.io',
  's3.eu-central-1.amazonaws.com/cdn2.wnhub.io': 'cdn2.wnhub.io',
}

// implement more params when required https://thumbor.readthedocs.io/en/latest/imaging.html
export const useProcessImage = (params: ImageSettings) => {
  if (!params.url) {
    return params.url
  }

  Object.keys(REPLACE_MAP).forEach((key) => {
    if (params.url.includes(key)) {
      params.url = params.url.replace(
        key,
        REPLACE_MAP[key as keyof typeof REPLACE_MAP]
      )
    }
  })

  try {
    if (!ALLOWED_DOMAINS.includes(new URL(params.url).host)) {
      return params.url
    }
  } catch (e: any) {
    console.error('Invalid image URL', params.url)
    return params.url
  }

  const { resizerUrl } = useRuntimeConfig().public
  return `${resizerUrl}/unsafe/${params.width || ''}x${
    params.height || ''
  }/filters:format(webp)/${params.url}`
}

export const useForceLoad = async () => {
  console.time('images: force load')
  // Only waiting for images to load. Note: iframes do not have a 'complete' attribute, so a custom attribute would be needed for similar handling.
  const ssrImages = Array.from(
    document.querySelectorAll('img[data-src]') as NodeListOf<HTMLImageElement>
  )
  ssrImages.forEach((element) => {
    if (element.dataset.src && element.src !== element.dataset.src) {
      element.src = element.dataset.src
    }
  })
  const promises = ssrImages.map(
    (element) =>
      new Promise((resolve) => {
        if (element.complete) resolve(true)
        else element.onload = element.onerror = resolve
      })
  )

  await Promise.all(promises)
  console.timeEnd('images: force load')
}
