import { createMetrika, isActive, debug } from './helpers'
import {
  getYandexECommerceProductFromTicket,
  sendYandexECommerceEvent,
  getYandexECommerceGoalId,
} from './ecommerce'
import { useAuth } from '~/stores/auth'

export enum YandexMetrikaGoals {
  PaymentStart = 'payment-start',
  HomeUpgradeClick = 'home-upgrade-click',
}

export default defineNuxtPlugin(() => {
  const router = useRouter()
  const authStore = useAuth()
  const tag = useRuntimeConfig().public.metrikaTag

  let metrika: (...args: any[]) => any

  async function initMetrika() {
    if (!tag || useDetect().isInIframe || !!useRoute().query.disableTracking) {
      return
    }

    try {
      metrika = await createMetrika(tag, authStore.profile?.id)

      router.afterEach((to, from) => {
        metrika?.('hit', to.path, { referer: from.path })
      })
    } catch (error: any) {
      console.warn('error on create metrika script', error)
    }
  }

  initMetrika()

  // In most cases, there is no need to wait for the request to be sent (don't use 'await')
  function reachGoal(goal: YandexMetrikaGoals, params: any): Promise<void> {
    return new Promise((resolve) => {
      if (isActive()) {
        setTimeout(() => {
          metrika?.('reachGoal', goal, params, () => resolve())
        }, 0)
      } else {
        debug('Send reachGoal', {
          goal,
          params,
        })

        resolve()
      }
    })
  }

  watch(
    () => authStore.profile,
    () => {
      if (authStore.profile) {
        metrika?.('userParams', {
          UserID: authStore.profile.id,
        })
      }
    }
  )

  return {
    provide: {
      yandexMetrika: {
        isActive,
        reachGoal,
        sendYandexECommerceEvent,
        getYandexECommerceProductFromTicket,
        getYandexECommerceGoalId,
        // if add new function, add it to index.server.js
      },
    },
  }
})
