import { debug, isActive } from './helpers'
import {
  type YandexEcommerceEvent,
  type YandexECommerceEventWrapper,
  type YandexECommerceProduct,
} from '~/models/ecommerce-yandex'
import type {
  Currency,
  TicketDetails,
  TicketsOrderItemDetails,
} from '~/models/tickets'
import { type EventLite, EventTypeNamesMap } from '~/models/events'
import { useECommerceHelper } from '~/composables/use-ecommerce-helper'
import { ECommerceBrands } from '~/models/ecommerce'

const {
  prepareECommerceItemId,
  prepareECommercePrice,
  getECommercePriceFromTicket,
} = useECommerceHelper()

export const ECOMMERCE_DATA_LAYER = 'metrikaDataLayer'

declare let window: {
  [ECOMMERCE_DATA_LAYER]: YandexECommerceEventWrapper[]
}

export function sendYandexECommerceEvent(event: YandexEcommerceEvent) {
  if (!isActive()) {
    debug('Send Yandex ECommerce event', event)
    return
  }

  window[ECOMMERCE_DATA_LAYER] ??= []

  window[ECOMMERCE_DATA_LAYER].push({
    ecommerce: event,
  })
}

export function getYandexECommerceProductFromTicket(
  ticket: TicketDetails | TicketsOrderItemDetails,
  currency: Currency,
  event?: EventLite | null,
  quantity: number = 1,
  coupon = '',
  discount = 0,
  position?: number
): YandexECommerceProduct {
  const brand = ECommerceBrands.HubTicket
  const category = event
    ? prepareYandexECommerceCategory([
        EventTypeNamesMap[event.type],
        event.name,
      ])
    : ''

  return {
    id: prepareECommerceItemId(ticket.id || 0, brand),
    name: ticket.name,
    price: getECommercePriceFromTicket(ticket, currency),
    coupon,
    discount: prepareECommercePrice(discount),
    quantity,
    category,
    brand,
    position,
  }
}

export function getYandexECommerceGoalId(): number | undefined {
  const goalId = useRuntimeConfig().public.metrikaEcommerceGoalId
  return goalId ? Number(goalId) : undefined
}

function prepareYandexECommerceCategory(
  categories: string[] & { length: 1 | 2 | 3 | 4 | 5 }
): string {
  return categories
    .map((category) => category.replace('/', ''))
    .filter((category) => category)
    .join('/')
}
