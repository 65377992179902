import {
  ECommerceBrands,
  ECommerceItemIdPrefix,
  ECommerceOrderIdPrefix,
  ECommerceOrderType,
} from '~/models/ecommerce'
import type {
  Currency,
  TicketDetails,
  TicketsOrderItemDetails,
} from '~/models/tickets'

function prepareECommerceItemId(
  id: string | number,
  brand: ECommerceBrands
): string {
  return ECommerceItemIdPrefix[brand] + id
}

function prepareECommerceOrderId(
  orderId: number,
  type = ECommerceOrderType.GeneralOrder
): string {
  return ECommerceOrderIdPrefix[type] + orderId
}

function prepareECommercePrice(price?: number): number {
  return (price || 0) / 100
}

function getECommercePriceFromTicket(
  ticket: TicketDetails | TicketsOrderItemDetails,
  currency: Currency
): number {
  const price =
    'prices' in ticket && ticket.prices
      ? ticket.prices[currency]
      : (ticket as TicketDetails)[
          currency.toLowerCase() as Lowercase<Currency>
        ] * 100

  return prepareECommercePrice(price || 0)
}

export const useECommerceHelper = () => {
  return {
    prepareECommerceItemId,
    prepareECommerceOrderId,
    prepareECommercePrice,
    getECommercePriceFromTicket,
  }
}
