export enum ECommerceBrands {
  WNConfTicket = 'wnconf_ticket',
  HubTicket = 'hub_ticket',
}

export const ECommerceItemIdPrefix = {
  [ECommerceBrands.WNConfTicket]: 'wnconf-ticket-',
  [ECommerceBrands.HubTicket]: 'hub-ticket-',
}

export enum ECommerceOrderType {
  GeneralOrder = 'general_order',
}

export const ECommerceOrderIdPrefix = {
  [ECommerceOrderType.GeneralOrder]: 'general-order-',
}
