<template>
  <div
    class="absolute top-[8px] right-[8px] h-[45px] min-w-[45px] w-[64px] bg-white/50 backdrop-blur-[20px] rounded-[8px]"
  >
    <BaseIcon
      name="wn_logo"
      class="h-full w-auto text-primary/70 py-[2px] px-[10px]"
    />
  </div>
</template>
