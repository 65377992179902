export const appHead = {"meta":[{"charset":"utf-8"},{"property":"og:site_name","content":"WN Hub"},{"name":"twitter:card","content":"summary_large_image"},{"name":"viewport","content":"width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"}],"link":[{"rel":"preconnect","href":"https://resizer.wnhub.io"},{"rel":"preconnect","href":"https://cdn.wnhub.io"},{"rel":"preconnect","href":"https://cdn2.wnhub.io"}],"style":[],"script":[{"innerHTML":"\n  ;(function() {\n    const elements = document.querySelectorAll('[data-src]');\n    let interactionCount = 0;\n\n    function load(element) {\n      if (element.dataset.src && element.src !== element.dataset.src) {\n        element.src = element.dataset.src\n      }\n      observer.unobserve(element)\n    }\n\n    const observer = new IntersectionObserver((entries, observer) => {\n      entries.forEach(entry => {\n        if (entry.isIntersecting) {\n          load(entry.target)\n        }\n      });\n    }, { threshold: 0 });\n\n    elements.forEach(element => {\n      observer.observe(element);\n    });\n\n    function onInteraction() {\n      interactionCount++;\n      if (interactionCount >= 5) {\n        console.log('perf: force lazy load on interaction')\n        elements.forEach(element => {\n          load(element);\n        });\n        window.removeEventListener('scroll', onInteraction);\n        window.removeEventListener('pointermove', onInteraction);\n        window.removeEventListener('keydown', onInteraction);\n      }\n    }\n    window.addEventListener('scroll', onInteraction);\n    window.addEventListener('pointermove', onInteraction);\n    window.addEventListener('keydown', onInteraction);\n})();\n","tagPosition":"bodyClose"}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false