import { createTag, debug, isActive } from './helpers'
import {
  getGoogleECommerceItemFromTicket,
  sendAddPaymentInfoGoogleECommerceEvent,
  sendAddToCartGoogleECommerceEvent,
  sendBeginCheckoutGoogleECommerceEvent,
  sendPurchaseGoogleECommerceEvent,
  sendRemoveFromCartGoogleECommerceEvent,
  sendViewCartGoogleECommerceEvent,
  sendViewItemGoogleECommerceEvent,
  sendViewItemListGoogleECommerceEvent,
} from '~/plugins/google-tag/ecommerce'

export default defineNuxtPlugin(() => {
  const tag = useRuntimeConfig().public.gtmTag

  function initTag() {
    try {
      return createTag(
        tag,
        !tag || useDetect().isInIframe || !!useRoute().query.disableTracking
      )
    } catch (error: any) {
      console.warn('error on create gtag script', error)
      return {
        gTagPush: debug,
        gTagView: debug,
        gTag: debug,
      }
    }
  }

  const { gTagPush, gTagView, gTag } = initTag()

  return {
    provide: {
      gtm: {
        isActive,
        gTagPush,
        gTagView,
        gTag,
        getGoogleECommerceItemFromTicket,
        sendAddToCartGoogleECommerceEvent,
        sendRemoveFromCartGoogleECommerceEvent,
        sendViewItemListGoogleECommerceEvent,
        sendViewItemGoogleECommerceEvent,
        sendPurchaseGoogleECommerceEvent,
        sendViewCartGoogleECommerceEvent,
        sendBeginCheckoutGoogleECommerceEvent,
        sendAddPaymentInfoGoogleECommerceEvent,
        // if add new function, add it to index.server.js
      },
    },
  }
})
